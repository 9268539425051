import { render, staticRenderFns } from "./HasilSurveyKajian.vue?vue&type=template&id=e45e422c&scoped=true&"
import script from "./HasilSurveyKajian.vue?vue&type=script&lang=js&"
export * from "./HasilSurveyKajian.vue?vue&type=script&lang=js&"
import style0 from "./HasilSurveyKajian.vue?vue&type=style&index=0&id=e45e422c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e45e422c",
  null
  
)

export default component.exports
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QTabs from 'quasar/src/components/tabs/QTabs.js';
import QTab from 'quasar/src/components/tabs/QTab.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QDialog,QCard,QCardSection,QBtn,QTabs,QTab});
