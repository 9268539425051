<template>
  <div style="height: 100vh;">
    <!-- logout dialog  -->
    <q-dialog v-model="confirm" persistent>
      <q-card class="q-py-md q-px-sm">
        <q-card-section>Apakah Anda akan keluar dari halaman survey?</q-card-section>
        <q-card-section class="row justify-evenly">
          <q-btn
            dense
            outline
            color="indigo-9"
            style="width: 80px;"
            no-caps
            @click="confirm = false"
            label="Tidak"
          />
          <q-btn
            dense
            unelevated
            color="indigo-9"
            style="width: 80px;"
            no-caps
            @click="closeSurvey"
            label="Ya"
          />
        </q-card-section>
      </q-card>
    </q-dialog>
    <!-- end of logout dialog  -->

    <div class="column no-wrap justify-between" style="height: 100vh;">
      <!-- user info  -->
      <div class="row items-center absolute-top-right q-mr-md">
        <div class="text-grey q-pr-xs">{{surveyor.nama}}</div>|
        <q-btn dense flat @click="confirm = true" color="red" label="logout" no-caps />
      </div>
      <!-- end of user info  -->
      <div class="col-auto q-pa-md q-pt-xl top text-center">
        <div class="text-h6">Hasil {{surveyor.nama}}</div>
      </div>

      <q-tabs dense v-model="selTab" class="bg-indigo-5 text-white">
        <q-tab v-for="(item,i) in listTab" :key="i" :name="item.name" :label="item.label"></q-tab>
      </q-tabs>

      <div v-if="selTab =='survey'" class="col-auto q-pa-md middle">
        <div class="text-h6 q-pa-md text-center">Nilai Survey Anda :</div>
        <div
          class="text-h6 q-pa-md text-center"
          :style="(lastSurvey.nilai_survey> 30?'color: red':'')"
        >{{lastSurvey.nilai_survey}}</div>
        <div class="text-center">
          <div
            v-if="lastSurvey.nilai_survey > 40"
          >Anda perlu melakukan karantina mandiri 14 hari terhitung mulai hari ini.</div>
          <div v-if="lastSurvey.nilai_survey <= 40">
            Hasil survey menunjukkan Anda dalam kondisi sehat dan baik
            <br />
            <br />Selamat melanjutkan aktifitas dan tetap jaga kesehatan dan patuhi protokol kesehatan
          </div>
        </div>
      </div>
      <div v-if="selTab =='kajian'" class="col-auto q-pa-md middle">
        <div class="text-h6 q-pa-md text-center">Status Kehadiran Kajian Anda periode ini:</div>
        <div
          class="text-h6 q-pa-md text-center"
          :style="(lastKajian.status_kehadiran == 'Berjalan'?'color: green':lastKajian.status_kehadiran == 'Tidak Berjalan'?'color: black':'color: red')"
        >{{lastKajian.status_kehadiran}}</div>
      </div>
      <div class="bottom row items-end">
        <div class="row" style="width: 100%;">
          <q-btn
            class="full-width q-pa-sm no-border-radius"
            label="Selesai"
            color="indigo-10"
            @click="closeSurvey"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "App",

  data() {
    return {
      selTab: "survey",
      listTab: [
        { name: "survey", label: "survey" },
        { name: "kajian", label: "kajian" }
      ],
      confirm: false,
      surveyor: {},
      lastSurvey: {
        nilai_survey: ""
      },
      lastKajian: null
    };
  },
  async mounted() {
    this.$http.get("/kode_unik", {}).then(result => {
      if (localStorage.getItem("kode_unik") != result.data.kode_unik) {
        this.$router.push("/");
      }
    });
    this.$http
      .get("/guru_by_noinduk/" + localStorage.getItem("surveyor"), {})
      .then(result => {
        this.surveyor = result.data;
      });
    //cek apakah sudah survey dalam periode ini. jika belum akan dialihkan ke halaman survey utk mengisi
    await this.$http
      .get("/validasi_survey_guru/" + localStorage.getItem("surveyor"), {})
      .then(result => {
        if (result.data) {
          this.lastSurvey = result.data;
        } else {
          this.$router.push("/survey-guru");
        }
      });
    // cek apakah sudah isi kajian dalam periode ini. jika belum akan dialihkan ke halaman survey utk mengisi
    await this.$http
      .get("/validasi_kajian_guru/" + localStorage.getItem("surveyor"), {})
      .then(result => {
        if (result.data) {
          this.lastKajian = result.data;
        } else {
          this.$router.push("/survey-guru");
        }
      });
  },
  methods: {
    closeSurvey() {
      localStorage.removeItem("kode_unik");
      localStorage.removeItem("surveyor");
      this.$router.push("/");
    }
  },
  computed: {}
};
</script>

<style lang="scss" scoped>
@font-face {
  font-family: "Montserrat";
  src: url("~@/assets/fonts/Montserrat-Medium.otf") format("truetype");
}

* {
  font-family: "Montserrat";
}
.middle {
  background-color: rgb(230, 230, 230);
  // padding: 10px;
  overflow: scroll;
  flex-grow: 99;
}
.custom-area {
  width: 90%;
  height: 220px;
  border-radius: 3px;
  padding: 8px;
}

.custom-info pre {
  width: 180px;
  font-size: 12px;
}
</style>